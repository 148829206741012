define("discourse/plugins/discourse-google-one-tap/discourse/connectors/after-login-buttons/google-one-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      didInsertElement() {
        if (window.google) {
          window.google.accounts.id.renderButton(document.getElementById("google_one_tap"), {
            theme: "outline",
            size: "large",
            width: 200,
            locale: this.siteSettings.default_locale
          });
        }
      }
    },
    shouldRender(_, component) {
      return component.siteSettings.google_one_tap_enabled;
    }
  };
});